import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from "@/assets/plugins/i18n";

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: {
      template: '<router-view></router-view>',
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
    ],
  },
  {
    path: '*',
    redirect() {
      return i18n.locale
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
