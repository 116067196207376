<template>
  <div class="About">
    <div
        v-for="(info, index) in mergeArrays"
        :key="index"
        class="About-Item"
    >
      <img :src="require(`@/assets/images/${info.num}`)" alt="number">
      <div class="About-ItemInfo">
        <span>{{ info.title }}</span>
        <p>{{ info.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      icons: [
        {num: "one.svg"},
        {num: "two.svg"},
        {num: "three.svg"},
      ]
    }
  },
  computed: {
    mergeArrays() {
      let mergeArr = this.icons.map((info, index) => ({...info, ...this.$t('about')[index]}));
      return mergeArr
    }
  }
}
</script>

<style lang="scss">
.About {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 6.25vw 8.75vw;
  background: linear-gradient(180deg, #151745 0%, #1F214F 100%);
  box-shadow: inset 0px -6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 1.25vw 0;
    background: linear-gradient(180deg, #151745 0%, #1F214F 100%);
    box-shadow: inset 0px -6px 20px rgba(0, 0, 0, 0.1);
  }

  &-Item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6.25vw;

    &:first-child {
      img {
        width: 7.5vw;
        margin-right: 8.438vw;
        @media (min-width: 1024px) {
          width: 1.78vw;
          margin-right: 1.93vw;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 1024px) {
      width: 25.7%;
      margin-bottom: 0;
    }

    img {
      width: 9.688vw;
      margin-right: 6.25vw;

      @media (min-width: 1024px) {
        width: 2.3vw;
        margin-right: 1.93vw;
        filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.85));
      }
    }

    span {
      margin-bottom: .625vw;
      font-weight: 700;
      font-size: 5vw;
      line-height: 1.5;
      color: var(--color-text-main);

      @media (min-width: 1024px) {
        margin-bottom: .105vw;
        font-size: 1.46vw;
        line-height: 1.21;
      }
    }

    p {
      font-weight: 500;
      font-size: 3.13vw;
      line-height: 1.6;
      color: var(--color-text-grey);

      @media (min-width: 1024px) {
        font-size: .73vw;
      }
    }
  }
}
</style>
