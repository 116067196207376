<template>
  <div class="home">
    <Header/>
    <Payments/>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Payments from "@/components/Payments";
import Footer from "@/components/Footer";

export default {
  name: 'Home',
  components: {
    Footer,
    Payments,
    Header
  }
}
</script>
