export function getPathName() {
    const pathName = window.location.hostname
    const cutHost = pathName.split('.').slice(0, 1).join()
    let info = {}

    switch (cutHost) {
        case '10freespins':
            info = {
                title: this.$t('header.10freespins.title'),
                text: this.$t('header.10freespins.text'),
            }
            break

        case '20freespins':
            info = {
                title: this.$t('header.20freespins.title'),
                text: this.$t('header.20freespins.text'),
            }
            break

        case 'exclusive125i':
            info = {
                title: this.$t('header.exclusive125i.title'),
                text: this.$t('header.exclusive125i.text'),
            }
            break

        case 'exclusive125ii':
            info = {
                title: this.$t('header.exclusive125ii.title'),
                text: this.$t('header.exclusive125ii.text'),
            }
            break

        case 'exclusive150i':
            info = {
                title: this.$t('header.exclusive150i.title'),
                text: this.$t('header.exclusive150i.text'),
            }
            break

        case 'exclusive150ii':
            info = {
                title: this.$t('header.exclusive150ii.title'),
                text: this.$t('header.exclusive150ii.text'),
            }
            break

        case 'welcome':
            info = {
                title: this.$t('header.welcome.title'),
                text: this.$t('header.welcome.text'),
            }
            break

        default:
            info = {
                title: this.$t('header.10freespins.title'),
                text: this.$t('header.10freespins.text'),
            }
    }
    return info
}