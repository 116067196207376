export function getParams() {
    let params = window.location.search
    let partsQ;
    let partsAmp;
    let keyword = "";
    if (params) {
        partsQ = params.split("?");
        partsAmp = partsQ[1].split("&")
        partsAmp.forEach(el => {
            let parts = el.split("=") || '';
            if (parts[0] === "PAR") {
                keyword = parts[1];
            }
        });
    }

    return `https://ninecas.onelink.me/B1pT/f078f43d/?af_adset=${keyword}&campaign_id=nine`
}
