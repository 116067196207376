<template>
  <header class="Header">
    <a
        :href="`https://pinocasino.com/${getAllParams()}`"
        target="_blank"
        class="Header-Link"
    >
      <img src="@/assets/images/logo.svg" alt="logo" class="Header-Logo">
    </a>
    <div class="Header-Content">
      <h1>
        {{ getPathName().title }}
      </h1>
      <span v-html="getPathName().text"></span>
      <a
          :href="`https://pinocasino.com/${getAllParams()}`"
          target="_blank"
          class="Sticky"
      >
        <span>
          {{ $t('button.title') }}
        </span>
        <span>
          {{ $t('button.text') }}
        </span>
      </a>
    </div>
    <img src="@/assets/images/oldman.png" alt="" class="Header-Person Oldman">
    <img src="@/assets/images/youngman.png" alt="" class="Header-Person Youngman">
    <img v-if="$i18n.locale === 'de'" src="@/assets/images/de-flag.png" alt="" class="Header-Person Flag">
  </header>
</template>

<script>

import {getPathName} from "@/assets/utils/getPathName";
import {getAllParams} from "@/assets/plugins/getParams";

export default {
  name: "Header",
  methods: {
    getPathName,
    getAllParams
  }
}
</script>

<style lang="scss">
.Header {
  position: relative;
  padding: 16.88vw 2vw 5vw;
  background-image: url(~@/assets/images/header-bg-mobile.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media (min-width: 1024px) {
    min-height: 41.67vw;
    padding: 9vw  2vw 7vw;
    background-image: url(~@/assets/images/header-bg.png);
  }

  &-Logo {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40.63vw;
    animation: 2s show-logo;

    @media (min-width: 1024px) {
      top: 6%;
      width: 17.947vw;
    }
  }

  &-Content {
    position: relative;
    z-index: 5;
    min-height: 117vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 800;
    text-align: center;
    color: var(--color-text-main);

    @media (min-width: 1024px) {
      justify-content: center;
      width: 60%;
      min-height: auto;
      margin: 0 auto;
    }

    > h1 {
      font-size: 10vw;
      line-height: 1;
      text-transform: uppercase;

      @media (min-width: 1024px) {
        font-size: 4.166vw;
      }
    }

    > span {
      margin-bottom: 24vw;
      padding: 0 2vw;
      font-size: 5.25vw;
      line-height: 1.40;
      text-transform: uppercase;

      @media (min-width: 1024px) {
        margin-bottom: 2.5vw;
        padding: 0;
        font-size: 1.667vw;
        line-height: 1.58;
      }
    }

    > a {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      padding: 2.94vw 24.23vw;
      background: linear-gradient(180deg, #1CDB9B -12%, #1C8562 87.33%);
      box-shadow: 0px 15px 40px rgba(57, 248, 184, 0.2);
      border-radius: 10vw;
      font-weight: 600;
      font-size: 5.63vw;
      line-height: 1.4;
      color: var(--color-text-main);

      animation: button-pulsar 5s infinite;

      @media (min-width: 1024px) {
        position: relative;
        padding: .469vw 3.646vw;
        font-size: .938vw;
      }

      span {
        &:last-child {
          font-weight: 500;
          font-size: 3.13vw;
          line-height: 1.60;

          @media (min-width: 1024px) {
            font-size: .521vw;
          }
        }
      }
    }
  }

  &-Person {
    position: absolute;
    z-index: 0;
  }

  .Oldman {
    width: 69vw;
    left: calc(50% - 57%);
    bottom: -4%;
    animation: 2s show-oldman;

    @media (min-width: 1024px) {
      width: 29.69vw;
      left: calc(50% - 46%);
      bottom: 0;
    }
  }

  .Youngman {
    width: 62.6vw;
    right: calc(50% - 67%);
    bottom: -3%;

    animation: 2s show-youngman;

    @media (min-width: 1024px) {
      width: 30.42vw;
      right: calc(50% - 50%);
      bottom: 0;
    }
  }
}

</style>