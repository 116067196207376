<template>
  <footer class="Footer">
    <div class="Footer-Items">
      <div class="Footer-Item License">
        <img v-for="(licence, index) in licences"
             :key="index"
             :src="require(`@/assets/images/${licence}`)"
             alt="license"
        >
      </div>
      <div class="Footer-Item Logo">
        <a
            :href="`https://pinocasino.com/${getAllParams()}`"
            target="_blank"
        >
          <img src="@/assets/images/logo-mini.svg" alt="logo">
        </a>
        <a
            :href="`https://pinocasino.com/${getAllParams()}`"
            target="_blank"
        >
          Bonus TnC’s
        </a>
      </div>
      <div class="Footer-Item Apps">
        <a
            v-for="(app, index) in apps"
            :key="'A'+index"
            :href="`https://pinocasino.onelink.me/LNiS/?af_adset=${getParams()}&campaign_id=pino&pid=mail`"
            target="_blank"
        >
          <img :src="require(`@/assets/images/${app.icon}`)" alt="icon">
          {{ app.text }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import {getParams} from '@/assets/utils/getParams'
import {getAllParams} from "@/assets/plugins/getParams";

export default {
  name: "Footer",
  data() {
    return {
      licences: [
        "gamecare.svg",
        "ga.svg",
        "gameanon.svg",
        "gambling-therapy.svg",
        "ic_18plus.svg",
        "ic_ssl.svg",
        "mga.svg",
      ],
      apps: [
        {icon: "android.svg", text: "App for Android"},
        {icon: "apple.svg", text: "App for IOS"},
      ]
    }
  },
  methods: {
    getParams,
    getAllParams
  },
}
</script>

<style lang="scss">
.Footer {
  margin-top: -14vw;
  padding: 23vw 0 11vw;
  background-color: var(--color-bg-footer);

  @media (min-width: 1024px) {
    margin-top: 0;
    padding: 2.5vw 0;
  }

  &-Items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 1024px) {
      flex-direction: row;
      width: 63.3%;
      margin: 0 auto;
    }
  }

  &-Item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .License {
    flex-wrap: wrap;
    order: 1;
    margin-bottom: 11.25vw;

    img {
      margin: 0 2.7vw 5vw;
      &:first-child {
        width: 11.06vw;
      }

      &:nth-child(2) {
        width: 17.5vw;
      }
      &:nth-child(3) {
        width: 10.7vw;
      }
      &:nth-child(4) {
        width: 22.64vw;
      }
      &:nth-child(5) {
        width: 11.64vw;
      }
      &:nth-child(6) {
        width: 26.4vw;
      }
      &:last-child {
        width: 44.1vw;
      }
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      order: 0;
      margin-bottom: 0;
      opacity: 0.4;

      img {
        margin: 0 1.024vw 0 0;

        &:first-child {
          width: 1.25vw;
        }

        &:nth-child(2) {
          width: 2.05vw;
        }
        &:nth-child(3) {
          width: 1.26vw;
        }
        &:nth-child(4) {
          width: 2.67vw;
        }
        &:nth-child(5) {
          width: 1.37vw;
        }
        &:nth-child(6) {
          width: 3.1vw;
        }
        &:last-child {
          width: 5.2vw;
          margin-right: 0;
        }
      }
    }
  }

  .Logo {
    flex-direction: column;
    order: 0;
    margin-bottom: 12.5vw;

    @media (min-width: 1024px) {
      order: 1;
      margin-bottom: 0;
    }

    img {
      width: 70vw;
      @media (min-width: 1024px) {
        width: 9.9vw;
      }
    }

    a + a {
      margin-top: 2.5vw;
      @media (min-width: 1024px) {
        margin-top: .417vw;
      }
    }

    a {
      font-weight: 600;
      font-size: 4.375vw;
      line-height: 1.60;
      color: var(--color-text-light);
      text-decoration: underline;

      @media (min-width: 1024px) {
        font-size: .73vw;
      }
    }
  }

  .Apps {
    order: 2;

    > a + a {
      margin-left: 9.136vw;
      @media (min-width: 1024px) {
        margin-left: 2.136vw;
      }
    }

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 3.75vw;
      line-height: 1.6;

      &:first-child {
        color: var(--color-android);

        img {
          width: 6vw;

          @media (min-width: 1024px) {
            width: 1.354vw;
          }
        }
      }

      img {
        width: 5.04vw;
        margin-right: 6vw;
        @media (min-width: 1024px) {
          width: 1.042vw;
          margin-right: .521vw;
        }
      }

      @media (min-width: 1024px) {
        font-size: .73vw;
      }
    }
  }
}

</style>