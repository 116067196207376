<template>
  <div
      id="app"
      :class="platformClass"
  >
    <router-view/>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      platform: 'platform',
      isMobile: 'isMobile',
      isTablet: 'isTablet',
      isDesktop: 'isDesktop',
      mobilePlatform: 'mobilePlatform',
      isLandscape: 'isLandscape'
    }),
    platformClass() {
      switch (this.platform) {
        case 'mobile':
        case 'tablet':
          return [this.platform, this.mobilePlatform, this.isLandscape ? 'landscape' : 'portrait'];
        default:
          return this.platform;
      }
    },

  }
}
</script>

<style lang="scss">
#app {
  @media (min-width: 1024px) {
    height: 100vh;
  }
}
</style>
