import '@/assets/styles/main.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router'
import store from './store';
import detectPlatform from '../src/assets/plugins/detectPlatform';
import VueScreen from 'vue-screen';
import i18n from '../src/assets/plugins/i18n'

Vue.use(VueScreen);

Vue.config.productionTip = false;

detectPlatform({ store });

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
