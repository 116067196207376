import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        platform: 'desktop',
        mobilePlatform: null,
        windowSize: [0, 0],
    },
    getters: {
        platform: state => state.platform,
        mobilePlatform: state => {
            return state.mobilePlatform
        },
        isMobile: state => state.platform === 'mobile',
        isTablet: state => state.platform === 'tablet',
        isDesktop: state => state.platform === 'desktop',
        isLandscape: state => state.windowSize[0] > state.windowSize[1],
        isPortrait: (_, getters) => !getters.isLandscape,
    },
    mutations: {
        setPlatform(state, platform) {
            state.platform = platform
        },
        setMobilePlatform(state, mobilePlatform) {
            state.mobilePlatform = mobilePlatform;
        },
    },
    actions: {
    },
    modules: {}
})
