<template>
  <div class="Payments">
    <img
        v-for="(icon, index) in icons"
        :key="index"
        :src="require(`@/assets/images/${icon.icon}`)"
        :style="{
          'width': $screen.width > 1024 ? icon.width : icon.mobWidth,
          order: $screen.width < 1024 ? icon.order : ''
        }"
        alt="payment icon"
    >
      <About class="Payments-About"/>
  </div>
</template>

<script>
import About from "./About";
export default {
  name: "Payments",
  components: {About},
  data() {
    return {
      icons: [
        {
          icon: "sofort.svg",
          width: "5.73vw",
          mobWidth: "23.625vw",
          // order: "0"
        },
        {
          icon: "paysafecard.svg",
          width: "9.375vw",
          mobWidth: "37.82vw",
          // order: "6"
        },
        {
          icon: "skrill.svg",
          width: "4.063vw",
          mobWidth: "14.376vw",
          // order: "1"
        },
        {
          icon: "neteller.svg",
          width: "7.032vw",
          mobWidth: "25.79vw",
          // order: "7"
        },
        {
          icon: "banktransfer.svg",
          width: "5.834vw",
          mobWidth: "21.5vw",
          //order: "3"
        },
        {
          icon: "ecopayz.svg",
          width: "6.44vw",
          mobWidth: "23.6vw",
          //order: "3"
        },
      ]
    }
  },
}
</script>

<style lang="scss">
.Payments {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 4.1vw 0 0;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    width: 63.3%;
    padding: 4.584vw 0 1.2vw;
  }

  > img {
    margin: 0 1.5vw 5vw;

    @media (min-width: 1024px) {
      margin: 0;
    }
  }

  &-About {
    order: 10;
    @media (min-width: 1024px) {
      position: absolute;
      top: -40%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>